interface IThemeMap {
    [key: string]: {
        primary: string,
        secondary: string,
        textPrimary: string,
        textSecondary: string,
        fill: string
    }
}

const ThemeMap: IThemeMap = {
    transparent: {
        primary: "",
        secondary: "bg-primary-green",
        textPrimary: "text-primary-blue",
        textSecondary: "text-white",
        fill: "white"
    },
    green: {
        primary: "bg-primary-green",
        secondary: "bg-primary-blue",
        textPrimary: "text-white",
        textSecondary: "text-primary-blue",
        fill: "white"
    },
    blue: {
        primary: "bg-primary-blue",
        secondary: "bg-primary-red",
        textPrimary: "text-white",
        textSecondary: "text-primary-green",
        fill: "white"
    },
    red: {
        primary: "bg-primary-red",
        secondary: "bg-primary-blue",
        textPrimary: "text-white",
        textSecondary: "text-primary-blue",
        fill: "white"
    },
    white: {
        primary: "bg-light-gray",
        secondary: "bg-primary-green",
        textPrimary: "text-primary-blue",
        textSecondary: "text-primary-green",
        fill: "black"
    },
    gray: {
        primary: "bg-primary-gray",
        secondary: "bg-primary-green",
        textPrimary: "text-primary-blue",
        textSecondary: "text-primary-green",
        fill: "black"
    },
    green_light: {
        primary: "bg-primary-green-light",
        secondary: "bg-primary-blue-light",
        textPrimary: "text-primary-blue",
        textSecondary: "text-primary-red",
        fill: "black"
    },
    yellow: {
        primary: "bg-yellow-400",
        secondary: "bg-primary-green",
        textPrimary: "text-black",
        textSecondary: "text-primary-blue",
        fill: "black"
    }
}

export default ThemeMap