

const Section = () => {
    return (
        <div className="text-center bg-primary-gray text-xs">
            	Preview Mode
        </div>
    )
}


export default Section