import React from 'react'
import { FooterData, IComponentProps } from '../../utils/types'
import { FaFacebook, FaInstagram, FaLinkedinIn } from 'react-icons/fa'
import { AiOutlineTwitter, AiOutlineInstagram, AiOutlineGithub } from 'react-icons/ai'
import Link from 'next/link'
import NewsLetter from '../sections/NewsLetter'

interface IIconMap {
    [key: string]: React.ReactNode
  }

const iconMap: IIconMap = {
    LinkedIn: <FaLinkedinIn size={25} color="white" />,
    Github: <AiOutlineGithub size={25} color="white" />,
    Twitter: <AiOutlineTwitter size={25} color="white" />,
    Facebook: <FaFacebook size={25} color="white" />,
    Instagram: <FaInstagram size={25} color="white" />,
  }


const Footer = ({ data }: IComponentProps<FooterData>) => {
    return (<></>)
}

export default Footer
