import React from 'react'
import Navbar from './Navbar'
import { NavbarData as navType, HeaderData, NavbarData } from '../../utils/types'
import ThemeMap from '../../styles/themes'
import { IoIosArrowDown } from 'react-icons/io'
import Link from 'next/link'
import Image from "next/image"

const ImageEndpoint = process.env.NEXT_PUBLIC_UPLOADS_URL


 
const placeholder = "PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiPgo8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHN0eWxlPSJmaWxsOnJnYig3MywgMTk3LCAxNzcpIiAvPgo8L3N2Zz4K"

interface HeaderProps {
    header: HeaderData,
    navbar: navType,
    secondaryNavbar?: NavbarData
}

const Header = ({ header, navbar, secondaryNavbar }: HeaderProps) => {
    const currentTheme = ThemeMap[header.theme]
    const secondNavTheme = ThemeMap[secondaryNavbar?.theme || 'white']
    const getStyles = () => {
        const styles = {
            image: {
                paddingTop: 0,
                paddingBottom: 0
            },
            noImage: {}
        }
        return header.bgImage?.data ? styles.image : styles.noImage
    }
    const styles = getStyles()
    const bgImg = header.bgImage?.data?.attributes.url

    const isExternalLink = (url?: string) => {
        if (typeof window !== 'undefined' && url) {
            let e = document.createElement('a');
            e.href = url;
            return e.host !== window.location.host;
        } else {
            return false
        }
    }

    const getAnchor = () => {
        if (header.callToAction === null || header.callToAction === undefined) return

        const link = header.callToAction.url 

        const relativeAnchor = <a 
            className={`page-cta btn btn-${header.callToAction?.theme}-${header.callToAction?.color} text-xl btn-rounded`}
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                let mainContent = document.getElementById(link.replace('#', ''))
                e.preventDefault()
                mainContent && mainContent.scrollIntoView({ behavior: 'smooth' })
            }}
            >
                {header.callToAction?.text}
            </a>
        

        if (link[0] === '#') {
            return relativeAnchor
        } 

        return <Link href={header.callToAction?.url}>
            <a {...isExternalLink(header.callToAction?.url) ? {target: '_blank', rel: 'noreferrer'} : {}} className={`page-cta w-fit bg-primary-${header.callToAction?.color} btn-${header.callToAction?.theme}-${header.callToAction?.color} py-3 px-8 btn-rounded`}>
                {header.callToAction?.text}
            </a>
        </Link>
    }

    return (
        <header
            className={`header relative ${currentTheme.primary} ${header.headerType === "fullscreen" && "h-screen"} flex flex-col p-0`}
            style={styles}
        >
            { bgImg && <Image key={bgImg} src={`${ImageEndpoint}${bgImg}`} layout="fill" objectFit="cover" priority={true} alt="" role="presentation" quality={50} placeholder="blur" blurDataURL={`data:image/svg+xml;base64,${placeholder}`} /> }

            <Navbar navbar={navbar} />
            {secondaryNavbar ?
                <div className={`secondary-navbar flex justify-center gap-10 py-3 px-5 ${secondNavTheme.primary} ${secondNavTheme.textSecondary}`}>
                    <ul className='flex w-full justify-around md:justify-center gap-10 text-sm md:text-base'>
                        {secondaryNavbar.links.map((item, index) => {
                            if (item.url.split('')[0] === '#') {
                                return (
                                    <li 
                                        key={index} 
                                        className='uppercase select-none cursor-pointer hover:underline'
                                        onClick={(e) => {
                                            let mainContent = document.getElementById(item.url.split('').slice(1, item.url.split('').length).join(''))
                                            e.preventDefault()
                                            mainContent && mainContent.scrollIntoView({ behavior: 'smooth' })
                                        }}
                                    >
                                        {item.text}
                                    </li>
                                )
                            } else {
                                return (
                                    <li key={index} className='uppercase'>
                                        <Link href={item.url}>
                                            {item.text}
                                        </Link>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </div>
                :
                null
            }
            {/* XXX better solution for header height, and seperate inner header component */}
            {header.headerType === "fullscreen" ?
                <div className={`header-content-full z-10 pb-28 px-10 md:px-40 ${header.highlightContent ? 'lg:px-56' : 'lg:px-56 lg:pr-96'} justify-center hero flex flex-col gap-7 md:gap-10  text-white w-full h-full ${!header.highlightContent && 'not-highlighted'}`}>
                    <div className={`relative ${header.highlightContent ? 'top-[3rem]' : ''}`}>
                        <div className={`relative z-[10] flex flex-col gap-5 ${header.highlightContent && 'items-center'}`}>
                            {/* COMPARE WITH MAIN TO APPLY PROPPER CONDITIONING AND REMAINING ATTRIBUTES */}
                            <h1 className={`page-title ${header.highlightContent && 'page-title-highlighted text-center'} ${currentTheme.textPrimary} md:max-w-4xl text-[2.8rem] md:text-[3.8rem] font-extrabold md:font-bold w-fit ${(header.bgImage?.data === null || header.bgImage?.data === undefined) && 'pb-6'}`} style={{ borderBottom: (header.bgImage?.data === null || header.bgImage?.data === undefined) ? 'solid 5px white' : '0' }}>
                                {header.title}
                            </h1>
                            {header.title && header.title !== "" ?
                                <h2 className={`page-subtitle ${currentTheme.textPrimary} ${header.highlightContent && 'text-center'} text-xl lg:text-xl xl:text-[1.5rem] max-w-3xl`}>
                                    {header.subtitle}
                                </h2>
                                :
                                null
                            }
                            {getAnchor()}
                        </div>
                        {header.highlightContent && <div className="z-[5] absolute h-[calc(100%_+_3rem)] w-[100vw] bg-white/10 backdrop-blur-sm top-[-1.5rem] -left-10 md:-left-40 lg:-left-56"
                            style={{ boxShadow: '6px 6px 9px 1px rgba(0,0,0,0.27)'}}></div>}
                    </div>
                    {header.enableAnchor && <a
                        className='page-anchor absolute flex justify-center bottom-32 md:bottom-10 lg:bottom-32 items-center w-8 h-8 border-solid border-2 border-white rounded-full hover:bg-white hover:fill-black cursor-pointer'
                        onClick={(e) => {
                            let mainContent = document.getElementById('main-content')
                            e.preventDefault()
                            mainContent && mainContent.scrollIntoView({ behavior: 'smooth' })
                        }}
                    >
                        <IoIosArrowDown color='white' size={43} />
                    </a>}
                </div> 
                :
                <div className={`header-content z-10 ${header.headerType != 'hidden' && "md:h-96"}`}>
                    <div className={`relative ${header.headerType === "hidden" ? "hidden" : ""} py-16 px-10 md:p-20 justify-center hero flex flex-col gap-7 md:gap-5  text-white w-fit h-full`}>
                        <div className={`relative z-[10] flex flex-col gap-5`}>
                            <h1 className={`page-title ${currentTheme.textPrimary} text-[2.7rem] md:text-[3rem]  font-bold w-fit ${(header.bgImage?.data === null || header.bgImage?.data === undefined) && 'pb-5'}`} style={{ borderBottom: (header.bgImage?.data === null || header.bgImage?.data === undefined) ? 'solid 5px white' : '0' }}>
                                {header.title}
                            </h1>
                            <h2 className={`page-subtitle ${currentTheme.textPrimary} text-xl md:text-[1.4rem] max-w-xl`}>
                                {header.subtitle}
                            </h2>
                            {getAnchor()}
                        </div>
                        {header.highlightContent && <div className="z-[5] w-[100vw] absolute -right-1/2 bottom-10 left-0 bg-white/10 backdrop-blur-sm top-10"
                            style={{ boxShadow: '6px 6px 9px 1px rgba(0,0,0,0.27)'}}></div>}
                    </div>
                </div>
            }


        </header>
    )
}

/*
 <div className="md:h-96"> 
*/


export default Header
