import { Fragment } from "react"
import { FooterData, GreenhouseJob, HeaderData, NavbarData, StrapiLink } from "../../utils/types"
import Header from "./Header"
import Footer from "./Footer"
import PreviewBar from "../preview"
import { User } from "../../pages/api/user"

type IProps = {
  children: React.ReactNode,
  header: HeaderData,
  navbarTheme: 'green' | 'red' | 'blue' | 'white' | 'transparent',
  preview: boolean,
  secondaryNavbar?: NavbarData,
  footer?: FooterData | null,
  user?: User,
  disableNavbar?: boolean
}
const links = [
  {
    text: "Our Story",
    url: "/about-us"
  },
  {
    text: "Ventures",
    url: "/ventures"
  },
  {
    text: "Team",
    url: "/team"
  },
  {
    text: "Library",
    url: "/library"
  },
  {
    text: "Join Us",
    url: "/join-us"
  },
  {
    text: "EIR Program",
    url: "/calling-for-entrepreneurs"
  }
] as StrapiLink[]

const footerLinks = [
  {
    text: "About",
    url: "/about-us"
  },
  {
    text: "Ventures",
    url: "/ventures"
  },
  {
    text: "Team",
    url: "/team"
  },
  {
    text: "Library",
    url: "/library"
  },
  {
    text: "Join Us",
    url: "/join-us"
  },
  {
    text: "Contact",
    url: "/contact"
  },
]

const socials = [
  {
    URL: 'https://www.linkedin.com/company/polymath-ventures/mycompany/',
    Title: 'Polymath Linked In',
    Icon: 'LinkedIn'
  },
  {
    URL: 'https://twitter.com/polymathdigital',
    Title: 'Polymath Twitter',
    Icon: 'Twitter'
  },
  {
    URL: 'https://www.instagram.com/polymathventures/',
    Title: 'Polymath Instagram',
    Icon: 'Instagram'
  },
]

const localFooter = {
  pageLinks: footerLinks,
  socials: socials
} as FooterData

const WebsiteLayout = ({ disableNavbar, children, header, navbarTheme, preview, secondaryNavbar, footer, user }: IProps) => {
  return (
    <Fragment>
      {preview && <PreviewBar /> }
      <Header 
        header={header} 
        navbar={{ 
          isDisabled: disableNavbar, 
          links: links, 
          theme: navbarTheme, 
          user: user ? user : undefined } as NavbarData
        } 
        secondaryNavbar={secondaryNavbar} 
      />
        {children}
      <Footer data={(footer === null || footer === undefined) ? localFooter : footer} />
    </Fragment>
  )
}

export default WebsiteLayout