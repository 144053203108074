import {
    useState
} from "react"
import Image from "next/image"
import Link from "next/link"
import { INavLink } from "../../utils/types"
import useWindowDimensions from "../../utils/hooks"
import ThemeMap from '../../styles/themes'
import { useRouter } from "next/router"
import useUser from "../../utils/useUser"
import { User } from "../../pages/api/user"
import fetchJson from "../../utils/fetchJson"

interface Navbar {
    theme: string,
    links: INavLink[],
    isDisabled: boolean,
    user?: User
}

interface NavbarProps {
    navbar: Navbar
}

const logos = {
    color: "https://res.cloudinary.com/polymath-ventures/image/upload/v1642045769/Logo_color_PV_n5bnuk.png",
    white: "https://res.cloudinary.com/polymath-ventures/image/upload/v1642045953/Logo_en_blanco_-_PV_tpdmww.png",
    blue: "https://res.cloudinary.com/polymath-ventures/image/upload/v1642045901/Logo_oscuro_PV_cxmn2i.png"
}

const Navbar = ({ navbar }: NavbarProps) => {
    const [mobileNavExpanded, setMobileNavExpanded] = useState<boolean>(false)
    const { width } = useWindowDimensions();
    const router = useRouter()
    const { user, mutateUser } = useUser()

    const currentTheme = ThemeMap[navbar.theme]

    const classes = `flex z-[99] ${currentTheme.primary} text-white ${navbar.isDisabled === true ? 'justify-center' : 'justify-between'} items-center px-10 py-2 navbar`

    return (
        <nav className={classes}>
                <Link href='https://polymathv.com'>
                    <a className={`z-[999] ${mobileNavExpanded ? 'fixed top-2 left-10' : 'relative'} md:relative`}>
                        <Image
                            src={(navbar.theme !== "white") ? logos.white : logos.color}
                            alt="Polymath"
                            height={38}
                            width={138}
                        />
                    </a>
                </Link>
        </nav>
    )
}

export default Navbar

